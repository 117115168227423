<template>
  <v-card class="card-complete-profile mb-4" flat color="error lighten-4">
    <v-card-title>
      {{ $t("checkout.completeProfileTitle") }}
    </v-card-title>

    <v-card-text v-html="$t('checkout.completeProfileSubTitle')"> </v-card-text>

    <v-card-actions>
      <v-btn
        large
        depressed
        block
        class="rounded-pill default--text"
        color="white"
        :to="{ name: 'ProfileUpdateInternal' }"
      >
        {{ $t("checkout.completeProfileBtn") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import CustomService from "@/service/customService";
import UserService from "@/commons/service/userService";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CheckoutCompleteProfile",
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    })
  },
  methods: {
    ...mapActions({
      loadCart: "cart/loadCart"
    }),
    async associateCard() {
      await CustomService.associateCard();
      await UserService.getUserDetail();
      this.loadCart();
    }
  }
};
</script>
